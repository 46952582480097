import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnDestroy,
} from '@angular/core';
import { SubSink } from 'subsink';
import {
  Maybe,
  SaveOption,
  PlaylistStatus,
  PlaylistDetailedFragment,
  Playlist,
} from '@designage/gql';
import { IPlaylistForm } from '@desquare/interfaces';
import {
  CurrentUserService,
  ToasterService,
  RoutingStateService,
  WindowService,
  PlaylistEditorService,
  PlaylistAsset,
  PlaylistAssetContent,
  PlaylistViewService,
  ChannelService,
  PlaylistService,
} from '@desquare/services';
import { Router } from '@angular/router';
import { ApolloError } from '@apollo/client/errors';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeletePlaylistDialogComponent } from '../delete-playlist-dialog/delete-playlist-dialog.component';
import { CommonModule } from '@angular/common';
import { PlaylistFormComponent } from '../playlist-form/playlist-form.component';

@Component({
  standalone: true,
  imports: [CommonModule, PlaylistFormComponent],
  selector: 'app-playlist-settings',
  template: `
    <app-playlist-form
      [invokedFrom]="invokedFrom"
      formId="playlistForm"
      [playlist]="playlist"
      (valid)="isFormValid = $event"
      (pristine)="isFormPristine = $event"
      (loading)="onLoadingChanged($event)"
      (values)="form = $event"
      (userAction)="onUserAction($event)"
      (playlistPublished)="playlistPublished.emit()"
    />
  `,
})
export class PlaylistSettingsComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  @Input() playlist!: Maybe<Playlist>;
  @Input() invokedFrom?: string;
  @Output() loading = new EventEmitter<boolean>();
  @Output() loaderMessage = new EventEmitter<string>();
  @Output() updatedPlaylist = new EventEmitter<Playlist>();
  @Output() playlistPublished = new EventEmitter<void>();

  isFormValid!: boolean;
  isFormPristine!: boolean;
  isFormLoading!: boolean;
  isAssetSelectionLoading!: boolean;
  formLoaderMessage!: string;
  saveOptions = SaveOption;
  form!: IPlaylistForm;

  constructor(
    private currentUserService: CurrentUserService,
    private toasterService: ToasterService,
    private router: Router,
    private modalService: NgbModal,
    private routingStateService: RoutingStateService,
    private playlistService: PlaylistService,
    private windowsService: WindowService,
    private playlistEditorService: PlaylistEditorService,
    private channelService: ChannelService,
    private playlistViewService: PlaylistViewService,
  ) {}

  get saveButtonEnabled() {
    return this.isFormValid && !this.isFormPristine && !this.isFormLoading;
  }

  get showDeleteButton() {
    return this.currentUserService.canManagePlaylist;
  }

  get saveEnabled() {
    return this.isFormValid && this.playlist;
  }

  get isPlaylistDraft() {
    return this.playlist?.status === PlaylistStatus.ReadyToPublish;
  }

  ngOnInit() {
    this.initVariables();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  initVariables() {
    this.isFormValid = false;
    this.isFormPristine = true;
    this.isFormLoading = false;
    this.isAssetSelectionLoading = false;
    this.formLoaderMessage = 'LOADING';
  }

  async updatePlaylist(saveOption: SaveOption) {
    if (this.playlist) {
      this.windowsService.scrollToTop();
      let toasterMessage = '';
      let loaderMessage = '';

      if (saveOption === SaveOption.Draft) {
        loaderMessage = 'DRAFTING_PLAYLIST';
        toasterMessage = 'DRAFT_PLAYLIST_SUCCESS';
      } else if (saveOption === SaveOption.Publish) {
        loaderMessage = 'PUBLISHING_PLAYLIST';
        toasterMessage = 'PUBLISH_PLAYLIST_SUCCESS';
      } else {
        loaderMessage = 'UPDATING_PLAYLIST';
        toasterMessage = 'UPDATE_PLAYLIST_SUCCESS';
      }
      this.loading.emit(true);
      this.loaderMessage.emit(loaderMessage);

      const input = this.playlistEditorService.getGqlUpdateInput(
        this.form,
        this.currentUserService.getCurrentProfileId(),
      );
      input.id = this.playlist.id;
      input.saveOption = saveOption;

      try {
        const updatePlaylist = await this.playlistService.updatePlaylist(input);

        if (
          updatePlaylist &&
          updatePlaylist.isSuccessful &&
          updatePlaylist.playlist
        ) {
          this.toasterService.success(toasterMessage);
          this.playlistEditorService.deletePendingPlaylist({
            playlistId: this.playlist?.id,
          });

          this.playlist = updatePlaylist.playlist as Playlist;
          this.updatePlaylistSettings();
          this.updatedPlaylist.emit(this.playlist);

          if (
            this.invokedFrom === 'channelPage' &&
            saveOption === SaveOption.Publish
          ) {
            this.channelService.publishedPlaylistsForCurrentChannelChanged.emit();
          }
        } else {
          this.toasterService.error('UNKNOWN_ERROR');
        }
        this.playlistEditorService.playlistSaveChanges.emit(true);
        this.isFormPristine = true;
      } catch (e) {
        const error = e as unknown as ApolloError;
        // error: (error: ApolloError) => {
        error.graphQLErrors.forEach((gqlError) => {
          console.error('updatePlaylist', gqlError);
          this.toasterService.handleGqlError(gqlError);
        });
      }
      this.loading.emit(false);
    }
  }

  updatePlaylistSettings() {
    if (this.playlist) {
      let setting = this.playlistViewService.getViewSettings(this.playlist.id);
      if (setting) {
        const assetViewStatuses: PlaylistAsset[] = [];
        for (const asset of this.playlist.assets) {
          const contentViewStatuses: PlaylistAssetContent[] = [];
          for (const content of asset.content) {
            if (content.id) {
              contentViewStatuses.push({
                contentId: content.id,
                isOpen: this.playlistViewService.isContentOpen(
                  asset.id,
                  content.id,
                ),
                isPinned: this.playlistViewService.isContentPinned(
                  asset.id,
                  content.id,
                ),
              });
            }
          }
          assetViewStatuses.push({
            assetId: asset.id,
            isCollapsed: this.playlistViewService.isAssetCollapsed(asset.id),
            contents: contentViewStatuses,
          });
        }
        setting = {
          playlistId: this.playlist.id,
          currentTab: this.playlistViewService.getPlaylistCurrentTab(),
          assets: assetViewStatuses,
        };
        this.playlistViewService.saveViewSettings(setting);
      }
    }
  }

  openDeletePlaylistDialog() {
    if (this.playlist) {
      const modal = this.modalService.open(DeletePlaylistDialogComponent, {
        backdrop: 'static',
      });
      modal.componentInstance.playlist = this.playlist;
      modal.componentInstance.activeChannels =
        this.playlist?.publishedPlaylist?.channels || [];
      modal.result
        .then(() => {
          this.deletePlaylist();
        })
        .catch(() => {});
    }
  }

  async deletePlaylist() {
    if (this.playlist && this.playlist.id) {
      this.windowsService.scrollToTop();
      this.loading.emit(true);
      this.loaderMessage.emit('DELETING_PLAYLIST');

      try {
        const success = await this.playlistService.deletePlaylists([
          this.playlist.id,
        ]);

        if (success) {
          this.toasterService.success('DELETE_PLAYLIST_SUCCESS');
          if (this.playlist?.id) {
            this.playlistEditorService.deletePendingPlaylist({
              playlistId: this.playlist?.id,
            });
            this.playlistViewService.deletePlaylistSettings(this.playlist.id);
          }
          this.router.navigate(['/profile/playlists']);
        } else {
          this.toasterService.error('UNKNOWN_ERROR');
        }
      } catch (e) {
        const error = e as unknown as ApolloError;
        error.graphQLErrors.forEach((gqlError) => {
          console.error('deletePlaylist', gqlError);
          this.toasterService.handleGqlError(gqlError);
        });
      }
    }
  }

  navigateBack() {
    this.router.navigateByUrl(this.routingStateService.getPreviousUrl());
  }

  onLoadingChanged(value: boolean) {
    this.loading.emit(value);
  }

  onUserAction(action: string) {
    if (action === 'PUBLISH') {
      this.updatePlaylist(SaveOption.Publish);
    }
    if (action === 'SAVE') {
      this.updatePlaylist(SaveOption.Update);
    }
    if (action === 'DELETE') {
      // this.deletePlaylist();
      this.openDeletePlaylistDialog();
    }
    if (action === 'START_REVERT') {
      this.loading.emit(true);
      this.loaderMessage.emit('Reverting to version...');
    }
    if (action === 'END_REVERT') {
      this.loading.emit(false);
    }
  }
}
