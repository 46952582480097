@if (showFilter) {
  <div class="d-flex align-items-center justify-content-between m-2 ms-0">
    <div class="flex-grow-1">
      <form
        class="position-relative search-field"
        [class.w-100]="filterValue !== ''"
      >
        <input
          name="search"
          type="text"
          (keyup)="applyFilter($event)"
          class="form-control form-control-dark search-input"
          placeholder="{{ 'SEARCH' | translate }}"
          [(ngModel)]="filterValue"
        />
        @if (filterValue) {
          <button
            (click)="clearFilter()"
            class="btn-close btn-close-white position-absolute filter-close-button"
            aria-label="Close"
          ></button>
        }
      </form>
    </div>
  </div>
}

<div class="designage-data-table-wrapper my-2">
  <mat-table
    #table
    matSort
    matSortActive="name"
    matSortDirection="asc"
    [dataSource]="dataSource"
    class="designage-data-table"
  >
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef> </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <!-- <mat-checkbox
          (click)="$event.stopPropagation()"
          [aria-label]="checkboxLabel(row.name)"
        >
        </mat-checkbox> -->
        <input
          type="checkbox"
          class="form-check-input"
          [checked]="isSelected(row.id)"
          (change)="selectHandler(row.id)"
        />
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef class="fs-6">Name</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="layout">
      <mat-header-cell *matHeaderCellDef class="fs-6">Layout</mat-header-cell>
      <mat-cell *matCellDef="let row">{{ row.layout?.name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="region">
      <mat-header-cell *matHeaderCellDef>Region</mat-header-cell>
      <mat-cell *matCellDef="let row">
        @if (isSelected(row.id)) {
          <app-layout-explorer
            [layout]="row.layout"
            [canSelect]="false"
            [selected]="getRegion(row.id)"
            [boundingBox]="'40px'"
            [showText]="false"
          ></app-layout-explorer>

          <button
            type="button"
            class="m-2 btn btn-sm btn-outline-primary w-100 text-truncate"
            container="body"
            [autoClose]="'outside'"
            [ngbPopover]="regionSelectPop"
            [ngbTooltip]="
              getRegion(row.id) || '' !== ''
                ? getRegion(row.id)
                : ('REGION_CANVAS_ROOT' | translate)
            "
          >
            {{
              getRegion(row.id) || '' !== ''
                ? getRegion(row.id)
                : ('REGION_CANVAS_ROOT' | translate)
            }}
          </button>
        }
        <ng-template #regionSelectPop>
          <div class="d-flex flex-column">
            <select
              class="form-control form-control-dark form-select"
              (change)="setRegion(row.id, $event)"
            >
              <option [value]="''" [selected]="getRegion(row.id) === ''">
                {{ 'REGION_CANVAS_ROOT' | translate }}
              </option>
              @for (region of getChannelRegions(row.id); track region) {
                <option
                  [value]="region"
                  [selected]="getRegion(row.id) === region"
                >
                  {{ region }}
                </option>
              }
            </select>
            <app-layout-explorer
              [layout]="row.layout"
              [canSelect]="true"
              [selected]="getRegion(row.id)"
              (selectedChange)="setRegion(row.id, $event)"
            ></app-layout-explorer>
          </div>
        </ng-template>
        <!-- <div
          *ngIf="isSelected(row.id)"
          ngbDropdown
          class="d-flex w-100"
          container="body"
        >
          <input
            type="button"
            class="form-control form-control-dark dropdown"
            ngbDropdownToggle
            value="{{
              getRegion(row.id) || '' !== ''
                ? getRegion(row.id)
                : ('REGION_CANVAS_ROOT' | translate)
            }}"
          />
          <div
            ngbDropdownMenu
            class="form-control form-control-dark shadowed-box text-white"
          >
            <table>
              <tr>
                <td>
                  <span
                    ngbDropdownItem
                    class="form-control form-control-dark dropdown-items"
                    (click)="setRegion(row.id, '')"
                    >{{ 'REGION_CANVAS_ROOT' | translate }}</span
                  >
                  <ng-container
                    *ngFor="let region of getChannelRegions(row.id)"
                  >
                    <span
                      ngbDropdownItem
                      class="form-control form-control-dark dropdown-items"
                      (click)="setRegion(row.id, region)"
                      >{{ region }}</span
                    ></ng-container
                  >
                </td>
                <td>
                  <app-layout-explorer
                    [layout]="row.layout"
                    canSelect="true"
                    [selected]="region"
                    (selectedChange)="setRegion(row.id, $event)"
                  ></app-layout-explorer>
                </td>
              </tr>
            </table>
          </div>
          <button
            *ngIf="mustUpdateRegion(row.id)"
            class="me-1 btn btn-outline-primary"
            (click)="resetRegion(row.id, ''); $event.stopPropagation()"
          >
            {{ 'REGION_RESET' | translate }}
          </button> 
        </div>-->
      </mat-cell>
    </ng-container>
    <!-- selectHandler(row) -->
    <mat-header-row
      *matHeaderRowDef="columnDefs; sticky: true"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: columnDefs"></mat-row>
  </mat-table>
</div>
