import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
} from '@angular/core';
import { Asset, Maybe, PlaylistType } from '@designage/gql';
import { IAssetContent } from '@desquare/interfaces';
import { SubSink } from 'subsink';
import {
  PlaylistViewService,
  PlaylistEditorService,
  SessionService,
} from '@desquare/services';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AssetRowComponent } from '../asset-row/asset-row.component';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';

@Component({
  standalone: true,
  imports: [TranslateModule, NgbTooltip, AssetRowComponent, LoaderComponent],
  selector: 'app-playlist-sequence-manager',
  template: ` <div class="d-flex justify-content-between">
      @if (showAddSequence) {
        <button
          [disabled]="assetChanging"
          type="button"
          (click)="addAsset()"
          class="btn btn-outline-primary btn-block ms-2 mb-2"
        >
          {{
            (playlistType === 'INTERACTIVE'
              ? 'ADD_SEQUENCE_INTERACTIVE'
              : 'ADD_SEQUENCE'
            ) | translate
          }}
        </button>
      }
      <div class="d-flex justify-content-center align-items-center me-3">
        <div
          class="pins"
          [hidden]="!isAnythingPinned()"
          (click)="unpinAll()"
          ngbTooltip="{{ 'UNPIN_ALL' | translate }}"
        >
          <i class="ti ti-pin2 pin-no-1"></i>
          <i class="ti ti-pin2 pin-no-2"></i>
        </div>
      </div>
    </div>

    @if (assetChanging) {
      <app-loader [message]="assetChangingMessage" />
    }
    @for (asset of assets; track asset.id) {
      <app-asset-row
        class="m-1 mb-2"
        [asset]="asset"
        [assetsCount]="assets.length"
        [collapseAll]="isCollapsed(asset.id)"
        [simpleUiActive]="simpleUiActive"
      />
    }`,
  styleUrls: ['./playlist-sequence-manager.component.scss'],
})
export class PlaylistSequenceManagerComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  @Input() simpleUiActive!: boolean;

  @Output() drop = new EventEmitter<Asset[]>();
  @Output() assetId = new EventEmitter<string>();
  @Output() assetContent = new EventEmitter<IAssetContent>();

  assets!: Asset[];
  profileId!: Maybe<string>;
  assetChanging = false;

  get assetChangingMessage() {
    return this.playlistEditorService.assetChangingMessage;
  }

  get playlistType() {
    return this.playlistEditorService.playlistType;
  }

  /** show add sequence in standard playlist, interactive playlist for now only have 1 sequence */
  get showAddSequence() {
    return this.playlistEditorService.playlistType === PlaylistType.Interactive
      ? this.assets.length === 0
      : true;
  }

  constructor(
    private playlistEditorService: PlaylistEditorService,
    private session: SessionService,
    private playlistViewService: PlaylistViewService,
  ) {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.profileId = this.session.profileId();

    // this.assets = this.playlistEditorService.editingSequences;
    this.playlistViewService.initCurrentPlaylistSettings();
    this.initSubscriptions();
  }

  initSubscriptions() {
    this.subs.sink =
      this.playlistEditorService.editingSequencesChange.subscribe(
        (assets: Asset[]) => {
          // console.log('editingSequencesChange', assets);

          this.assets = this.playlistEditorService.editingSequences;
        },
      );

    this.subs.sink = this.playlistEditorService.assetMutating.subscribe(
      (value: boolean) => {
        this.assetChanging = value;
      },
    );
  }

  isAnythingPinned() {
    return this.playlistViewService.isAnythingPinned();
  }
  unpinAll() {
    this.playlistViewService.unpinAll();
  }
  addAsset() {
    if (this.profileId) {
      this.playlistEditorService.createAsset(this.profileId, true);
    }
  }

  isCollapsed(assetId: string) {
    return this.assets.length > 2
      ? this.playlistViewService.isAssetCollapsed(assetId)
      : false;
  }
}
