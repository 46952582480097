import { CommonModule, NgTemplateOutlet, TitleCasePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { AssetItem, AssetType, Maybe, PlaylistType } from '@designage/gql';
import { DatepickerComponent } from '@desquare/components/common/src/datepicker/datepicker.component';
import { DurationPipe } from '@desquare/components/common/src/pipe/duration/duration.pipe';
import { TimepickerComponent } from '@desquare/components/common/src/timepicker/timepicker.component';
import { IScheduledDayIndicator } from '@desquare/interfaces';
import { PlaylistEditorService } from '@desquare/services';
import { NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    NgbPopover,
    NgbTooltip,
    TranslateModule,
    DatepickerComponent,
    TimepickerComponent,
    NgTemplateOutlet,
    DurationPipe,
    TitleCasePipe,
  ],
  selector: 'designage-content-row-simple-details',
  templateUrl: './content-row-simple-details.component.html',
  styleUrls: ['./content-row-simple-details.component.scss'],
})
export class ContentRowSimpleDetailsComponent implements OnInit {
  @Input() assetItem!: AssetItem;
  @Output() assetItemChange = new EventEmitter<AssetItem>();
  @Input() assetType = AssetType;
  @Input() isStartDateChecked!: Boolean;
  @Input() campaignStartInput!: Maybe<string>;
  @Input() validCampaignStartFormat = true;
  @Input() isEndDateChecked!: Boolean;
  @Input() campaignEndInput!: Maybe<string>;
  @Input() notAllDays!: boolean;
  @Input() validCampaignEndFormat = true;
  @Input() showResizeCrop!: Boolean;
  @Input() disableResizeCrop!: Boolean;
  @Input() isResizeCropChecked!: Boolean;
  @Input() resizeCropMethod!: string;
  @Input() resizeCropMethods!: string[];
  @Input() showTransition!: Boolean;
  @Input() transitionEffect!: string;
  @Input() transitionEffects!: string[];
  @Input() transitionDuration!: number;
  @Input() topRightControls!: TemplateRef<any>;
  @Input() scheduleDays!: IScheduledDayIndicator[];
  @Input() contentDuration!: number;
  @Input() validDurationFormat = true;
  @Input() validDurationValue = true;
  @Input() timeDurationString!: number;

  @Input() timeDurationInput!: string;
  @Output() timeDurationInputChange = new EventEmitter<string>();

  @Output() onCheckBoxStartDate = new EventEmitter();
  @Output() onCampaignStartClose = new EventEmitter<Maybe<string>>();
  @Output() onCheckBoxEndDate = new EventEmitter();
  @Output() onCampaignEndClose = new EventEmitter<Maybe<string>>();
  @Output() onCheckBoxResizeCrop = new EventEmitter();
  @Output() setResizeCrop = new EventEmitter<string>();
  @Output() setSelectedTransition = new EventEmitter<string>();
  @Output() setTransitionDuration = new EventEmitter();
  @Output() onDurationInputClose = new EventEmitter();

  get isInteractive() {
    return this.playlistType === PlaylistType.Interactive;
  }
  get playlistType() {
    return this.playlistEditorService.playlistType;
  }

  constructor(private playlistEditorService: PlaylistEditorService) {}

  ngOnInit(): void {}

  onSimpleDurationInputClose() {
    console.log('this.timeDurationInput', this.timeDurationInput);
    this.timeDurationInputChange.emit(this.timeDurationInput);
    this.onDurationInputClose.emit();
  }
}
