<div
  class="d-flex justify-content-start align-items-center hover-fade px-3 col-gap-2"
>
  <i class="ri-calendar-todo-line text-white"></i>
  <!-- daySchedule -->
  <div class="d-flex flex-column align-items-center overflow-hidden">
    @if (campaignStartInput || campaignEndInput || notAllDays) {
      <div class="d-flex flex-fill">
        <div class="d-flex flex-grow-1 align-items-center">
          @if (campaignStartInput) {
            <div
              class="d-flex flex-grow-1 flex-wrap justify-content-center align-items-baseline col-gap-2"
            >
              <div class="detail-info">
                {{ 'DO_NOT_PLAY_BEFORE' | translate }}:
              </div>
              <h5>{{ campaignStartInput }}</h5>
            </div>
          }
          @if (campaignStartInput && campaignEndInput) {
            <div
              class="d-flex flex-shrink-1 px-2 justify-content-center align-items-baseline"
            >
              <i class="ri-arrow-right-fill fs-2"></i>
            </div>
          }
          @if (campaignEndInput) {
            <div
              class="d-flex flex-grow-1 flex-wrap justify-content-center align-items-baseline col-gap-2"
            >
              <div class="detail-info">
                {{ 'DO_NOT_PLAY_AFTER' | translate }}:
              </div>
              <h5>{{ campaignEndInput }}</h5>
            </div>
          }
        </div>
      </div>
    } @else {
      <div
        class="d-flex justify-content-start align-items-center"
        [ngbTooltip]="'Click to add a shchedule to this content'"
      >
        <h5>Always playing</h5>
      </div>
    }

    @if (notAllDays) {
      <div
        class="d-flex flex-nowrap justify-content-center align-items-end w-100 px-2"
      >
        <label class="form-label detail-info m-0 px-2">Weekdays:</label>
        <ng-container *ngTemplateOutlet="topRightControls"></ng-container>
      </div>
    }
  </div>

  <div class="d-flex flex-shrink-1 justify-content-start cursor-pointer">
    <i
      [ngbPopover]="popSchedule"
      [autoClose]="'outside'"
      class="ri-pencil-fill text-primary ps-2 hover-fade-target"
    ></i>
  </div>

  <ng-template #popSchedule>
    <div class="d-flex align-items-start" (click)="onCheckBoxStartDate.emit()">
      <input class="pe-2" type="checkbox" [checked]="isStartDateChecked" />
      <label class="ps-1 form-label start-date-label detail-info"
        >{{ 'DO_NOT_PLAY_BEFORE' | translate }}:</label
      >
    </div>
    <div class="start-date-picker date-picker">
      @if (campaignStartInput) {
        <designage-datepicker
          [disabled]="!isStartDateChecked"
          [(date)]="campaignStartInput"
          [maxDate]="campaignEndInput ?? ''"
          (dateChange)="onCampaignStartClose.emit(campaignStartInput)"
        />
      }
      @if (!validCampaignStartFormat) {
        <div class="date-error text-danger">
          {{ 'INVALID_DATE_FORMAT_ERROR' | translate }}
        </div>
      }
    </div>
    <div class="py-3">
      <div class="d-flex align-items-start" (click)="onCheckBoxEndDate.emit()">
        <input
          class="end-date-checkbox"
          type="checkbox"
          [checked]="isEndDateChecked"
        />
        <label class="ps-1 form-label end-date-label detail-info"
          >{{ 'DO_NOT_PLAY_AFTER' | translate }}:</label
        >
      </div>
      <div class="end-date-picker date-picker">
        @if (campaignEndInput) {
          <designage-datepicker
            [disabled]="!isEndDateChecked"
            [(date)]="campaignEndInput"
            [minDate]="campaignStartInput ?? ''"
            (dateChange)="onCampaignEndClose.emit(campaignEndInput)"
          />
        }
        @if (!validCampaignEndFormat) {
          <div class="date-error text-danger">
            {{ 'INVALID_DATE_FORMAT_ERROR' | translate }}
          </div>
        }
      </div>
    </div>
    <div class="weekdays">
      <label class="form-label detail-info d-block"
        >{{ 'PLAY_SEQUENCE_ON' | translate }}:</label
      >
      <!-- <label class="detail-info d-block">Play this on days:</label> -->
      <div class="">
        <ng-container [ngTemplateOutlet]="topRightControls" />
      </div>
    </div>
  </ng-template>
</div>

<hr />

<div class="d-flex flex-fill align-items-center px-3">
  <!-- Duration -->
  <div
    [ngbTooltip]="
      'Content duration (video is set from clip and cannot be altered)'
    "
    class="d-flex flex-fill justify-content-start align-items-baseline"
  >
    <i class="ri-timer-line text-white"></i>
    <div class="d-flex align-items-baseline hover-fade">
      <h5 class="mx-2">
        {{ timeDurationString | duration }}
      </h5>
      @if (assetItem.type !== assetType.Video) {
        <span class="d-flex flex-shrink-1 justify-content-start cursor-pointer">
          <designage-timepicker
            (click)="$event.stopPropagation()"
            [(time)]="timeDurationInput"
            (timeChange)="onSimpleDurationInputClose()"
            [asInputField]="false"
          />
        </span>
      }
    </div>
  </div>

  <!-- Transition -->
  <div class="d-flex flex-fill justify-content-start px-3">
    <div
      *ngIf="assetItem.type !== assetType.Video"
      class="d-flex align-items-baseline"
      [ngbTooltip]="'Transistion in to this image'"
    >
      <i
        class="ri-hourglass-2-line text-white"
        style="transform: rotateZ(90deg)"
      ></i>
      <div class="d-flex align-items-baseline hover-fade">
        <h5 class="mx-2">
          {{ transitionEffect === '' ? 'CUT' : (transitionEffect | titlecase) }}
        </h5>
        @if (transitionEffect !== 'CUT') {
          <h6>{{ transitionDuration }}s</h6>
          <span
            class="d-flex flex-shrink-1 justify-content-start cursor-pointer"
          >
            <i
              [ngbPopover]="popTransition"
              [autoClose]="'outside'"
              class="ri-pencil-fill text-primary ps-2 hover-fade-target"
            ></i>
          </span>
        }
      </div>
    </div>
  </div>
</div>
<ng-template #popTransition>
  <div class="d-flex align-items-center col-gap-2">
    <label class="m-0 form-label detail-info"
      >{{ 'TRANSITION' | translate }}:</label
    >
    <div ngbDropdown placement="bottom">
      <input
        style="height: 2.1rem; width: 5rem"
        type="button"
        class="form-control form-control-dark dropdown"
        ngbDropdownToggle
        value="{{ transitionEffect | translate }}"
      />
      <div
        ngbDropdownMenu
        class="form-control form-control-dark shadowed-box text-white"
      >
        @for (effect of transitionEffects; track effect) {
          @if (effect !== transitionEffect) {
            <span
              ngbDropdownItem
              class="form-control form-control-dark dropdown-items"
              (click)="setSelectedTransition.emit(effect)"
              >{{ effect | translate }}</span
            >
          }
        }
      </div>
    </div>
    @if (transitionEffect != 'CUT') {
      <div class="d-flex" (mousedown)="$event.stopPropagation()">
        <input
          style="height: 2.1rem; width: 5rem"
          class="form-control form-control-dark"
          type="number"
          [(value)]="transitionDuration"
          (change)="setTransitionDuration.emit($event)"
          min="0.25"
          [(max)]="contentDuration"
          step="0.25"
          placeholder="0.00"
        />
        <label
          style="width: auto; margin: auto 0 0 0"
          class="px-1 border-0 form-control form-control-dark"
          >s</label
        >
      </div>
    }
  </div>
</ng-template>

<hr />

@if (showResizeCrop) {
  <div class="d-flex flex-fill align-items-center px-3">
    <!-- Resize and Crop -->
    <div class="d-flex flex-fill justify-content-start">
      <div
        ngbTooltip="{{ 'RESIZE_CROP' | translate }}"
        class="d-flex align-items-baseline"
      >
        <i class="ri-crop-2-line text-white"></i>
        <div class="d-flex align-items-baseline hover-fade">
          <h5 class="mx-2">
            {{
              isResizeCropChecked
                ? (resizeCropMethod | translate)
                : ('NOT_SET' | translate)
            }}
          </h5>
          <span
            class="d-flex flex-shrink-1 justify-content-start cursor-pointer"
          >
            <i
              [ngbPopover]="popCropResize"
              [autoClose]="'outside'"
              class="ri-pencil-fill text-primary ps-2 hover-fade-target"
            ></i>
          </span>
        </div>
      </div>
    </div>
  </div>
}

<ng-template #popCropResize>
  @if (showResizeCrop) {
    <div class="d-flex flex-column align-items-center col-gap-2">
      <div ngbDropdown>
        <input
          type="button"
          class="form-control form-control-dark dropdown"
          ngbDropdownToggle
          value="{{ resizeCropMethod | translate }}"
        />
        <div
          ngbDropdownMenu
          class="form-control form-control-dark shadowed-box text-white"
        >
          @for (method of resizeCropMethods; track method) {
            @if (method !== resizeCropMethod) {
              <span
                ngbDropdownItem
                class="form-control form-control-dark dropdown-items"
                (click)="setResizeCrop.emit(method)"
                >{{ method | translate }}</span
              >
            }
          }
        </div>
      </div>
    </div>
  }
</ng-template>
