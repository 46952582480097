@if (uiMode$ | async; as uiMode) {
  <form
    class="d-flex flex-column h-100 overflow-hidden"
    [id]="formId"
    [formGroup]="playlistForm"
    (submit)="submitPlaylistForm($event, playlistForm.value)"
    (keydown.enter)="$event.preventDefault()"
  >
    <div class="ps-3 pt-2 row">
      <div
        id="PlaylistFormHeader"
        class="col row"
        [class.PlaylistFormHeader]="uiMode.simpleUiMode"
      >
        <!-- Playlist name -->
        <div
          class="mb-2 text-truncate col-12 d-flex align-items-center sliding-panel-header"
        >
          <div class="icon-appear-on-hover">
            @if (isEditingName) {
              <input
                #playlistNameInput
                (click)="$event.stopPropagation()"
                class="form-control form-control-dark"
                formControlName="name"
                (blur)="updatePlaylistName()"
                (keydown.enter)="updatePlaylistName()"
                placeholder="{{ 'PLAYLIST_NAME' | translate }}"
              />
            } @else {
              <label
                class="d-inline pointer-text"
                [class.text-secondary]="!playlistForm.controls.name.value"
                (click)="editPlaylistNameInput()"
              >
                {{
                  playlistForm.controls.name.value || 'PLAYLIST_NAME'
                    | translate
                }}
              </label>
              <i
                (click)="editPlaylistNameInput(); $event.stopPropagation()"
                class="px-2 playlist-name-edit-icon ri-pencil-fill text-primary"
              ></i>
              @if (playlistForm.controls.name.value === '') {
                <span class="text-danger">
                  {{ 'PLAYLIST_NAME_IS_REQUIRED' | translate }}
                </span>
              }
            }
          </div>
        </div>

        <!-- Stats and Schedule -->
        @if (!uiMode.simpleUiMode) {
          <div class="row col-12 align-items-center pe-0 my-3 my-md-0">
            <!-- Stats -->
            <div class="d-none d-sm-block col-6 col-lg-4">
              <div class="d-flex mb-2 mb-lg-0">
                <div class="row" style="gap: 0.25rem">
                  <div class="row">
                    <label class="col">{{ 'LAST_UPDATED' | translate }}:</label>
                    <div class="col">
                      {{
                        (updatedAt | date: 'short') || ('NO_DATA' | translate)
                      }}
                    </div>
                  </div>
                  <div class="row">
                    @if (!isInteractive) {
                      <div class="col">
                        <label class="">{{ 'SEQUENCES' | translate }}:</label>
                        {{
                          this.playlist?.assets?.length ||
                            ('NO_DATA' | translate)
                        }}
                      </div>
                      <div class="col">
                        <label class="">{{ 'RESOURCES' | translate }}:</label>
                        {{ resourceCount || ('NO_DATA' | translate) }}
                      </div>
                    }
                  </div>
                  <!-- <div class="published-name"></div>
                 <label *ngIf="playlist?.publishedPlaylist?.name">
                   {{ 'PUBLISHED_PLAYLIST_NAME' | translate }}: {{ playlist?.publishedPlaylist?.name || ('NO_DATA' | translate) }}
                 </label> -->
                </div>
              </div>
            </div>

            <!-- Playlist Schedule -->
            @if (!isInteractive) {
              <div
                class="col-12 col-sm-6 col-lg-4 d-flex flex-column align-items-end"
              >
                <!-- Playlist Dont play before -->
                <div class="d-flex align-items-center">
                  <div class="me-2" style="width: 10rem">
                    <div class="form-check d-flex align-items-center">
                      <input
                        id="startDate"
                        type="checkbox"
                        class="form-check-input"
                        [checked]="isStartDateChecked"
                        (change)="onCheckBoxStartDate($event)"
                      />
                      <label class="ms-1 form-check-label" for="startDate"
                        >{{ 'DO_NOT_PLAY_BEFORE' | translate }}:</label
                      >
                    </div>
                    <div class="error">
                      @if (
                        !playlistForm.controls.startDate.pristine &&
                        playlistForm.controls['startDate'].hasError(
                          'dateFormat'
                        )
                      ) {
                        <span class="text-danger">
                          {{ 'INVALID_DATE_FORMAT' | translate }}
                        </span>
                      }
                      @if (
                        ((!playlistForm.controls.startDate.pristine ||
                          !playlistForm.controls.endDate.pristine) &&
                          playlistForm.hasError('dateRange')) ||
                        invalidDateRange
                      ) {
                        <span class="text-danger">
                          {{ 'INVALID_DATE_RANGE' | translate }}
                        </span>
                      }
                    </div>
                  </div>
                  <div class="">
                    <designage-datepicker
                      [disabled]="!isStartDateChecked"
                      [(date)]="playlistStartDate"
                      [maxDate]="playlistEndDate"
                      (dateChange)="playlistScheduleChange()"
                    >
                    </designage-datepicker>
                  </div>
                </div>

                <!-- Playlist Dont play after -->
                <div class="d-flex align-items-center">
                  <div class="me-2" style="width: 10rem">
                    <div class="form-check d-flex align-items-center">
                      <input
                        id="endDate"
                        type="checkbox"
                        [checked]="isEndDateChecked"
                        class="form-check-input"
                        (change)="onCheckBoxEndDate($event)"
                      />
                      <label class="ms-1 form-check-label" for="endDate"
                        >{{ 'DO_NOT_PLAY_AFTER' | translate }}:</label
                      >
                    </div>
                  </div>
                  <div class="">
                    <designage-datepicker
                      [disabled]="!isEndDateChecked"
                      [(date)]="playlistEndDate"
                      [minDate]="playlistStartDate"
                      (dateChange)="playlistScheduleChange()"
                    >
                    </designage-datepicker>
                  </div>
                </div>
              </div>
            }
            <!-- Save and publish buttons -->
            <div
              class="col-12 col-lg-4 d-flex justify-content-end pe-0 mb-3 mb-sm-0"
            >
              <div
                class="d-flex flex-row-reverse flex-lg-column align-items-end justify-content-between justify-content-lg-evenly w-100"
              >
                <div class="w-auto pt-2">
                  <button
                    type="button"
                    class="btn mx-1"
                    [disabled]="!changeDetected"
                    [class.btn-warning]="
                      playlist?.status != playlistStatus.ReadyToPublish ||
                      changeDetected
                    "
                    [class.btn-outline-warning]="
                      playlist?.status === playlistStatus.ReadyToPublish &&
                      !changeDetected
                    "
                    (click)="save()"
                  >
                    {{ 'SAVE' | translate }}
                  </button>
                  <button
                    type="button"
                    class="btn ms-1"
                    [disabled]="
                      playlist?.status != playlistStatus.ReadyToPublish ||
                      changeDetected
                    "
                    [class.btn-success]="
                      playlist?.status === playlistStatus.ReadyToPublish &&
                      !changeDetected
                    "
                    [class.btn-outline-success]="
                      playlist?.status != playlistStatus.ReadyToPublish ||
                      changeDetected
                    "
                    (click)="publish()"
                  >
                    {{ 'PUBLISH' | translate }}
                  </button>
                  @if (playlist?.status && invokedFrom != 'channelPage') {
                    <button
                      type="button"
                      class="btn mx-1 btn-outline-danger"
                      (click)="delete()"
                    >
                      {{ 'DELETE' | translate }}
                    </button>
                  }
                </div>
                <div class="pt-2 w-auto d-flex flex-nowrap align-items-center">
                  <!-- Status label -->
                  <label class="me-2 d-none d-md-block"
                    >{{ 'STATUS' | translate }}:</label
                  >
                  <span
                    class="mx-1 badge"
                    [class.bg-warning]="
                      playlist?.status === playlistStatus.Draft &&
                      !changeDetected
                    "
                    [class.bg-warning]="
                      playlist?.status === playlistStatus.ReadyToPublish &&
                      !changeDetected
                    "
                    [class.bg-primary]="
                      playlist?.status === playlistStatus.Published &&
                      !changeDetected
                    "
                    [class.bg-danger]="
                      playlist?.status === playlistStatus.Deprecated &&
                      !changeDetected
                    "
                    [class.bg-danger]="changeDetected"
                  >
                    {{
                      playlist?.status && !changeDetected
                        ? (playlist?.status! | translate)
                        : ('NOT_SAVED' | translate)
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
    @if (responsiveUiService.sm()) {
      <as-split
        [direction]="responsiveUiService.lg() ? 'horizontal' : 'vertical'"
        useTransition="true"
        unit="percent"
      >
        <as-split-area
          [size]="responsiveUiService.lg() ? '60' : '40'"
          minSize="35"
          class="pt-2"
        >
          <ng-container
            [ngTemplateOutlet]="playlistSequenceManager"
          ></ng-container>
        </as-split-area>
        <as-split-area
          [size]="responsiveUiService.lg() ? '40' : '60'"
          minSize="30"
          class="pt-4"
        >
          <ng-container
            [ngTemplateOutlet]="playlistSettingsPanels"
          ></ng-container>
        </as-split-area>
      </as-split>
    } @else {
      <div class="d-flex flex-column h-100">
        <ul
          ngbNav
          [(activeId)]="activeIdSettingsPanel"
          #nav="ngbNav"
          class="mx-3 nav nav-pills"
        >
          @if (responsiveUiService.isMobileDevice()) {
            <li [ngbNavItem]="1" class="nav-item">
              <a ngbNavLink>
                {{ 'PLAYLIST' | translate }}
              </a>
              <ng-template ngbNavContent>
                <ng-container
                  *ngTemplateOutlet="playlistSequenceManager"
                ></ng-container>
              </ng-template>
            </li>
          }
          @if (!isInteractive) {
            <li [ngbNavItem]="2" class="nav-item" [destroyOnHide]="false">
              <a ngbNavLink>
                {{ 'VIEWER' | translate }}
              </a>
              <ng-template ngbNavContent>
                <ng-container *ngTemplateOutlet="contentPreview"></ng-container>
              </ng-template>
            </li>
          }
          <li [ngbNavItem]="3" class="nav-item">
            <a ngbNavLink>
              {{ 'CONTENT' | translate }}
            </a>
            <ng-template ngbNavContent>
              <ng-container *ngTemplateOutlet="mediaGallery"></ng-container>
            </ng-template>
          </li>

          @if (
            invokedFrom != 'channelPage' && currentUserService.canManageChannels
          ) {
            <li [ngbNavItem]="4" class="nav-item">
              <a ngbNavLink>
                {{ 'CHANNEL_ASSIGN' | translate }}
              </a>
              <ng-template ngbNavContent>
                <ng-container
                  *ngTemplateOutlet="channelRegionSelection"
                ></ng-container>
              </ng-template>
            </li>
          }

          @if (playlist?.status) {
            <li [ngbNavItem]="5" class="nav-item">
              <a ngbNavLink>
                {{ 'HISTORY' | translate }}
              </a>
              <ng-template ngbNavContent>
                <ng-container
                  *ngTemplateOutlet="playlistVersion"
                ></ng-container>
              </ng-template>
            </li>
          }

          <!-- <li class="nav-item" *ngIf="advancedScheduleEnabled">
            <a
              (click)="setCurrentTab(tabs.SCHEDULE)"
              data-toggle="tab"
              aria-expanded="true"
              [class.active]="currentTab === tabs.SCHEDULE"
              class="nav-link"
            >
              {{ 'SCHEDULE' | translate }}
            </a>
            <ng-template ngbNavContent>
              <ng-container
                *ngTemplateOutlet="channelRegionSelection"
              ></ng-container>
            </ng-template>
          </li> -->
        </ul>
        <div [ngbNavOutlet]="nav" class="overflow-auto h-100"></div>
      </div>
    }

    <ng-template #playlistSequenceManager>
      <div class="h-100 overflow-x-hidden">
        <app-playlist-sequence-manager
          [simpleUiActive]="uiMode.simpleUiMode || isInteractive"
        ></app-playlist-sequence-manager>
      </div>
    </ng-template>

    <ng-template #playlistSettingsPanels>
      <div class="d-flex flex-column h-100">
        <ul
          ngbNav
          [(activeId)]="activeIdSettingsPanel"
          #nav="ngbNav"
          class="mx-3 nav nav-pills"
        >
          @if (responsiveUiService.isMobileDevice()) {
            <li [ngbNavItem]="1" class="nav-item">
              <a ngbNavLink>
                {{ 'PLAYLIST' | translate }}
              </a>
              <ng-template ngbNavContent>
                <ng-container
                  *ngTemplateOutlet="playlistSequenceManager"
                ></ng-container>
              </ng-template>
            </li>
          }
          @if (!isInteractive) {
            <li [ngbNavItem]="2" class="nav-item" [destroyOnHide]="false">
              <a ngbNavLink>
                {{ 'VIEWER' | translate }}
              </a>
              <ng-template ngbNavContent>
                <ng-container *ngTemplateOutlet="contentPreview"></ng-container>
              </ng-template>
            </li>
          }
          <li [ngbNavItem]="3" class="nav-item">
            <a ngbNavLink>
              {{ 'CONTENT' | translate }}
            </a>
            <ng-template ngbNavContent>
              <ng-container *ngTemplateOutlet="mediaGallery"></ng-container>
            </ng-template>
          </li>

          @if (
            invokedFrom != 'channelPage' && currentUserService.canManageChannels
          ) {
            <li [ngbNavItem]="4" class="nav-item">
              <a ngbNavLink>
                {{ 'CHANNEL_ASSIGN' | translate }}
              </a>
              <ng-template ngbNavContent>
                <ng-container
                  *ngTemplateOutlet="channelRegionSelection"
                ></ng-container>
              </ng-template>
            </li>
          }

          @if (playlist?.status) {
            <li [ngbNavItem]="5" class="nav-item">
              <a ngbNavLink>
                {{ 'HISTORY' | translate }}
              </a>
              <ng-template ngbNavContent>
                <ng-container
                  *ngTemplateOutlet="playlistVersion"
                ></ng-container>
              </ng-template>
            </li>
          }

          <!-- <li class="nav-item" *ngIf="advancedScheduleEnabled">
            <a
              (click)="setCurrentTab(tabs.SCHEDULE)"
              data-toggle="tab"
              aria-expanded="true"
              [class.active]="currentTab === tabs.SCHEDULE"
              class="nav-link"
            >
              {{ 'SCHEDULE' | translate }}
            </a>
            <ng-template ngbNavContent>
              <ng-container
                *ngTemplateOutlet="channelRegionSelection"
              ></ng-container>
            </ng-template>
          </li> -->
        </ul>
        <div [ngbNavOutlet]="nav" class="overflow-auto h-100"></div>
      </div>
    </ng-template>
    <ng-template #contentPreview>
      <app-content-preview
        #preview
        [isBrowserRes]="responsiveUiService.lg() || true"
        [channels]="channels"
        [showPublishedButton]="!!publishedAssets.length"
        [publishedAssets]="publishedAssets"
        [selectedChannelRegions]="selectedChannels"
        [playlist]="playlist"
        previewButtonText="PREVIEW_PLAYLIST"
        publishedButtonText="PUBLISHED_PLAYLIST"
        noContentText="NO_ADDED_VIEW"
        [showSimulateSchedule]="true"
        [showAspectRatio]="true"
        (simulateDateTimeChanges)="onSimulateDateTimeChange($event)"
      ></app-content-preview>
    </ng-template>

    <ng-template #channelRegionSelection>
      <app-channel-region-selection
        [channels]="channels"
        [selectedChannels]="selectedChannels"
        (selectedChannelsChange)="setSelectedChannels($event)"
      ></app-channel-region-selection>
    </ng-template>

    <ng-template #mediaGallery>
      <app-media-list
        [enableCheckbox]="false"
        [galleryView]="true"
        [showCreativeEditor]="false"
        [showHeader]="false"
        [enableSlidePanel]="false"
        [showFolderExplorerText]="false"
        [folderExplorerWidth]="150"
      />
      <!-- <app-media-gallery [profileId]="profileId"></app-media-gallery> -->
    </ng-template>

    <ng-template #playlistVersion>
      <app-playlist-version
        [playlistId]="playlist?.id!"
        [(activeChannels)]="activeChannels"
        (revertButtonClick)="revertToVersion($event)"
        (createCopyButtonClick)="createCopy($event)"
      ></app-playlist-version>
    </ng-template>
  </form>
}
