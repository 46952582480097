@if (versions$ | async; as versions) {
  @if (!versions?.length) {
    <div>{{ 'NO_VERSIONS' | translate }}</div>
  }
  <div class="py-3">
    <div class="d-flex align-items-center form-group">
      <label class="">{{ 'VERSION' | translate }}</label>
      <div
        ngbDropdown
        #versionDropdown="ngbDropdown"
        class="form-control form-control-dark form-control-sm w-auto px-4 pointer"
      >
        <div
          id="versionDropdown"
          ngbDropdownToggle
          class="d-flex align-items-center"
        >
          <span
            class="badge dropdownItemStatus"
            [class.bg-success]="
              selectedVersion?.status === playlistStatus.Active
            "
            [class.bg-secondary]="
              selectedVersion?.status === playlistStatus.Archived
            "
          >
            {{ selectedVersion?.status | translate }}
          </span>
          <h5 class="my-1 mx-2">
            {{ selectedVersion?.createdAt | date: 'medium' }}
          </h5>
        </div>
        @if (versions && versions.length > 1) {
          <div
            ngbDropdownMenu
            aria-labelledby="versionDropdown"
            class="form-control form-control-dark shadowed-box text-white"
          >
            <div class="dropDownItemContainer">
              @for (version of versions; track version?.id) {
                @if (version && version?.id !== selectedVersion?.id) {
                  <div
                    ngbDropdownItem
                    class="d-flex align-items-center form-control form-control-dark pointer"
                    (click)="
                      setSelectedVersion(version.id);
                      $event.stopPropagation();
                      versionDropdown.close()
                    "
                  >
                    <span
                      class="badge dropdownItemStatus"
                      [class.bg-success]="
                        version.status === playlistStatus.Active
                      "
                      [class.bg-secondary]="
                        version.status === playlistStatus.Archived
                      "
                    >
                      {{ version.status | translate }}
                    </span>
                    <span class="ms-2">{{
                      version.createdAt | date: 'medium'
                    }}</span>
                  </div>
                }
              }
            </div>
          </div>
        }
      </div>
      <button
        type="button"
        class="btn btn-sm btn-outline-success ms-2"
        (click)="revert()"
        ngbTooltip="{{ 'REVERT_TO_VERSION' | translate }}"
      >
        {{ 'REVERT' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-sm btn-outline-warning ms-2"
        (click)="createCopy()"
        ngbTooltip="{{ 'CREATE_COPY' | translate }}"
      >
        {{ 'COPY' | translate }}
      </button>
    </div>
  </div>
  @if (selectedVersion && selectedVersion?.comment) {
    <div class="gridComment">
      <small class="form-text text-muted"
        >{{ 'COMMENT' | translate }}: {{ selectedVersion.comment }}</small
      >
    </div>
  }
  <div class="card">
    @if (selectedVersion && selectedVersion?.assets) {
      <H4 class="card-header"
        >{{ 'PUBLISHED' | translate }}
        {{ selectedVersion.createdAt | date: 'medium' }}</H4
      >
      <div class="card-body">
        <app-asset-row
          class="asset-row"
          *ngFor="let asset of selectedVersion?.assets"
          [asset]="asset"
          [currentPlaylistStatus]="selectedVersion?.status"
          [readOnly]="true"
          [collapseAll]="true"
        ></app-asset-row>
      </div>
    } @else {
      <H3>{{ 'NO_HISTORY' | translate }}</H3>
    }
  </div>
}
