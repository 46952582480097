<div class="ps-2 h-100 d-flex flex-column">
  <!-- header section -->
  <div class="d-flex justify-content-between">
    <h1>Interactive page actions</h1>
    <div class="d-flex align-items-center">
      <button
        tabindex="-1"
        class="mx-2 btn btn-outline-success"
        (click)="saveAndClose()"
        placement="left"
        ngbTooltip="{{ 'CREATIVE_EDITOR_SAVE_TT' | translate }}"
      >
        {{ 'SAVE_AND_CLOSE' | translate }}
      </button>
      <button
        tabindex="-1"
        class="mx-2 btn btn-outline-warning"
        (click)="close()"
        placement="left"
        ngbTooltip="{{ 'CREATIVE_EDITOR_CLOSE_TT' | translate }}"
      >
        {{ 'CLOSE' | translate }}
      </button>
    </div>
  </div>

  <!-- DEBUG -->
  <!-- <p>fetched layout:</p> -->
  <!-- <pre style="color: white"> {{ layout | json }} </pre> -->
  <!-- <p>layout form:</p> -->
  <!-- <pre style="color: wheat"> {{ layoutManageForm.value | json }} </pre> -->

  <!-- layout form -->
  <app-layout-form
    class="h-100"
    [parentFormGroup]="layoutManageForm"
    [isManageMode]="true"
    [editorSourceBlob]="source"
    [layoutType]="LayoutType.Interactive"
    [backgroundUrl]="backgroundUrl"
    [height]="height"
    [width]="width"
  />
  <!-- <app-zone-splitter></app-zone-splitter> -->
</div>
